import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { MUIStyledCommonProps, Theme } from "@mui/system";

/** スキル詳細画像３ プロパティ */
export interface ISkillDetailImage3Props extends MUIStyledCommonProps<Theme> {}

/** スキル詳細画像３ */
export default function SkillDetailImage3({ ...props }: ISkillDetailImage3Props) {
    return (
        <div { ...props }>
            <StaticImage
                src="../../../images/Skill3.png"
                alt="Skill3"
                placeholder="blurred"
                layout="fixed"
                width={600}
                height={428}
            />
        </div>
    )
}