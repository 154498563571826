import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { MUIStyledCommonProps, Theme } from "@mui/system";

/** スキル詳細画像１ プロパティ */
export interface ISkillDetailImage1Props extends MUIStyledCommonProps<Theme> {}

/** スキル詳細画像１ */
export default function SkillDetailImage1({ ...props }: ISkillDetailImage1Props) {
    return (
        <div { ...props }>
            <StaticImage
                src="../../../images/Skill1.png"
                alt="Skill1"
                placeholder="blurred"
                layout="fixed"
                width={600}
                height={428}
            />
        </div>
    )
}