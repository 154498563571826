import * as React from "react";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";
import SkillImage1 from "../atoms/skill/skillImage1";
import SkillImage2 from "../atoms/skill/skillImage2";
import SkillImage3 from "../atoms/skill/skillImage3";
import SkillImage4 from "../atoms/skill/skillImage4";
import SkillBackground from "../atoms/skill/skillBackground";
import SkillTitle from "../atoms/skill/skillTitle";
import SkillText from "../atoms/skill/skillText";
import RightArrowButton from "../atoms/common/rightArrowButton";
import Skill from "../atoms/skill/skill";

/** スキルカード プロパティ */
export interface ISkillCardProps extends MUIStyledCommonProps<Theme> {
    /** ID */
    id: string;
    /** タイトルテキスト */
    title: string;
    /** テキスト */
    textList: string[];
    /** スキル */
    skills: string[];
    /** 画像 */
    image: 1 | 2 | 3 | 4;
    /** 「詳しく見る」クリックイベント */
    onSeeMore: () => void;
}

const MySkillBackground = styled(SkillBackground)({})
const MyContentsArea = styled('div')({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
})
const MyTextArea = styled('div')({
    display: "flex",
    flexDirection: "column",
    rowGap: "1.5rem"
})
const MySkillTextArea = styled('div')({
    display: "flex",
    flexDirection: "column",
    rowGap: "0.5rem"
})
const MySkillArea = styled('div')({
    display: "flex",
    columnGap: "1.0rem",
    marginTop: "1.0rem"
})
const MyImageArea = styled('div')({})
const MySeeMoreArea = styled('div')({
    display: "flex",
    justifyContent: "end",
    marginRight: "1.0rem"
})

/** スキルカード */
export default function SkillCard({ id, title, textList, skills, image, onSeeMore, ...props }: ISkillCardProps) {

    const image1 = image === 1 ? <SkillImage1 /> : image === 2 ? <SkillImage2 /> : image === 3 ? <SkillImage3 /> : <SkillImage4 />

    return (
        <div { ...props }>
            <MySkillBackground>
                <MyContentsArea>
                    <MyTextArea>
                        <SkillTitle text={title} />
                        <MySkillTextArea>
                            {textList.map((text, i) => {
                                return (<SkillText text={text} key={`skill-text-${id}-${i}`} />)
                            })}
                        </MySkillTextArea>
                        <MySkillArea>
                            {skills.map((skill, i) => {
                                return (<Skill text={skill} key={`skill-${id}-${i}`} />)
                            })}
                        </MySkillArea>
                    </MyTextArea>
                    <MySeeMoreArea>
                        <RightArrowButton text="詳しく見る" onClick={onSeeMore} />
                    </MySeeMoreArea>
                </MyContentsArea>
                <MyImageArea>
                    {image1}
                </MyImageArea>
            </MySkillBackground>
        </div>
    )
}