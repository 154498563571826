import * as React from "react";
import { Typography } from "@mui/material";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";

/** ニーズサブタイトル プロパティ */
export interface INeedSubTitleProps extends MUIStyledCommonProps<Theme> {
  /** テキスト */
  text: string;
}

const MyDivider = styled('div')({
  marginTop: "-2.8rem",
  backgroundColor: "white",
  height: "1.5rem"
})

/** ニーズサブタイトル */
export default function NeedSubTitle({
  text,
  ...props
}: INeedSubTitleProps) {
  return (
    <div {...props}>
      <Typography
        fontFamily={"Yu Gothic"}
        fontSize={"4.0rem"}
        fontWeight={600}
        letterSpacing={"0.5rem"}
      >
        {text}
      </Typography>
      <MyDivider />
    </div>
  );
}
