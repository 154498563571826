import * as React from "react";
import { Typography } from "@mui/material";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";

/** スキル詳細ダイアログ内テキスト プロパティ */
export interface ISkillDetailTextProps extends MUIStyledCommonProps<Theme> {
  /** ID */
  id: string;
  /** テキスト */
  strings: string[];
  /** 最終行 */
  end?: React.ReactNode;
}

const MyRoot = styled("div")({});

/** スキル詳細ダイアログ内テキスト */
export default function SkillDetailText({
  id,
  strings,
  end,
  ...props
}: ISkillDetailTextProps) {
  return (
    <MyRoot {...props}>
      {strings.map((text, i) => {
        return (
          <Typography
            fontWeight={500}
            fontSize="1.8rem"
            lineHeight="3.5rem"
            key={`skill-detail-text-${id}-${i}`}
          >
            {text}
          </Typography>
        );
      })}
      {end}
    </MyRoot>
  );
}
