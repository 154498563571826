import * as React from "react";
import { Typography } from "@mui/material";
import { MUIStyledCommonProps, Theme } from "@mui/system";

/** スキル詳細ダイアログタイトル プロパティ */
export interface ISkillDetailTitleProps extends MUIStyledCommonProps<Theme> {
  /** テキスト */
  text: string;
}

/** スキル詳細ダイアログタイトル */
export default function SkillDetailTitle({
  text,
  ...props
}: ISkillDetailTitleProps) {
  return (
    <div {...props}>
      <Typography
        fontSize={"2.7rem"}
        fontWeight={700}
        letterSpacing={"0.1rem"}
      >
        {text}
      </Typography>
    </div>
  );
}
