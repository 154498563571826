import * as React from "react";
import { MUIStyledCommonProps, Theme } from "@mui/system";
import { IconButton } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";

/** 左ボタン プロパティ */
export interface ILeftButtonProps extends MUIStyledCommonProps<Theme> {
    /** スクロール先要素のid */
    onClick: () => void
    /** フォントサイズ */
    fontSize?: string
}

/** 左ボタン */
export default function LeftButton({ onClick, fontSize, ...props }: ILeftButtonProps) {
    return (
        <IconButton onClick={onClick} {...props}>
            <KeyboardArrowLeft style={{ fontSize: fontSize }} />
        </IconButton>
    )
}