import * as React from "react";
import { Typography } from "@mui/material";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";

/** セクション説明文 プロパティ */
export interface ISectionDescriptionProps extends MUIStyledCommonProps<Theme> {
  /** ID */
  id: string;
  /** テキスト */
  strings: string[];
  /** 行高さ */
  lineHeight?: string;
}

const MyRoot = styled("div")({
  display: "flow-root",
  letterSpacing: "0.24rem",
  "& p": {
    float: "left",
  },
});

/** セクション説明文 */
export default function SectionDescription({
  id,
  strings,
  lineHeight,
  ...props
}: ISectionDescriptionProps) {
  return (
    <MyRoot {...props}>
      {strings.map((text, i) => {
        return (
          <Typography
            variant="h1"
            component="p"
            lineHeight={lineHeight ? lineHeight : "6.6rem"}
            key={`section-description-${id}-${i}`}
          >
            {text}
          </Typography>
        );
      })}
    </MyRoot>
  );
}
