import * as React from "react";
import { Button, Typography } from "@mui/material";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";

/** 左矢印ボタン プロパティ */
export interface ILeftArrowButtonProps extends MUIStyledCommonProps<Theme> {
  /** テキスト */
  text: string;
  /** クリックイベント */
  onClick: () => void;
}

const MyRoot = styled(Button)({
  display: "flex",
  alignItems: "end",
  padding: "2.0rem 1.0rem"
})
const MyArrowLeft = styled('div')({
  height: "1.8rem",
  borderLeft: "3px solid #000000",
  transform: "rotate(45deg)",
  marginRight: "-0.8rem",
  marginBottom: "-0.2rem"
})

/** 左矢印ボタン */
export default function LeftArrowButton({
  text,
  onClick,
  ...props
}: ILeftArrowButtonProps) {
  return (
    <MyRoot onClick={onClick} {...props}>
      <MyArrowLeft />
      <Typography
        fontSize={"2.0rem"}
        fontWeight={700}
        letterSpacing="0.1rem"
        borderBottom="3px solid #000000"
        paddingLeft="3.0rem"
      >
        {text}
      </Typography>
    </MyRoot>
  );
}
