import * as React from "react";
import { Typography } from "@mui/material";
import { MUIStyledCommonProps, Theme } from "@mui/system";

/** スキルテキスト プロパティ */
export interface ISkillTextProps extends MUIStyledCommonProps<Theme> {
  /** テキスト */
  text: string;
}

/** スキルテキスト */
export default function SkillText({
  text,
  ...props
}: ISkillTextProps) {
  return (
    <div {...props}>
      <Typography
        fontSize={"2.0rem"}
        fontWeight={700}
      >
        {text}
      </Typography>
    </div>
  );
}
