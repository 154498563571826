import * as React from "react";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";
import SkillDetailText from "../../../atoms/skill/skillDetailText";
import SkillDetailSection from "../../../atoms/skill/skillDetailSection";
import Skill from "../../../atoms/skill/skill";
import SkillDetailImage4 from "../../../atoms/skill/skillDetailImage4";

/** スキル詳細カード ワークショップ・講座 コンテンツ1 プロパティ */
export interface IContent1Props extends MUIStyledCommonProps<Theme> { }

const MySkillDetailText = styled(SkillDetailText)({
    width: "80%"
})
const MyDescriptionArea = styled("div")({
    marginTop: "3.0rem",
    display: "flex"
})
const MyEffectArea = styled("div")({
    display: "flex",
    columnGap: "1.0rem"
})
const MyTextArea = styled("div")({
    paddingLeft: "2.0rem",
    display: "flex",
    flexDirection: "column",
    rowGap: "3.0rem",
    width: "50%"
})
const MyImageArea = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%"
})

/** スキル詳細カード ワークショップ・講座 コンテンツ1 */
export default function Content1({ ...props }: IContent1Props) {
    return (
        <div {...props}>
            <MySkillDetailText
                id="skillcard1-workshop"
                strings={[
                    "説明",
                    "説明"]}
            />
            <MyDescriptionArea>
                <MyTextArea>
                    <SkillDetailSection title="効果">
                        <MyEffectArea>
                            <Skill text="2.構想の可視化" />
                        </MyEffectArea>
                        <SkillDetailText id="skillcard1-workshop-effect" strings={[
                            "あああ",
                            "いいい"
                        ]} />
                    </SkillDetailSection>
                </MyTextArea>
                <MyImageArea>
                    <SkillDetailImage4 />
                </MyImageArea>
            </MyDescriptionArea>
        </div>
    );
}