import * as React from "react";
import { MUIStyledCommonProps, Theme } from "@mui/system";
import SkillCard from "../../../blocks/skillCard";
import SkillDetailDialog from "../../skillDetailDialog";
import GraRecoContent1 from "./content1";
import GraRecoContent2 from "./content2";

/** スキル詳細カード グラフィックレコーディング プロパティ */
export interface ICardProps extends MUIStyledCommonProps<Theme> { }

/** スキル詳細カード グラフィックレコーディング */
export default function Card({ ...props }: ICardProps) {
    const [detailOpened, setDetailOpened] = React.useState<boolean>(false);

    return (
        <>
            <SkillCard 
                id="skillcard1-skill-gra-reco" 
                title="グラフィックレコーディング" 
                textList={["絵と文字を用いてリアルタイムで会話を記録します。"]} 
                skills={["1.会話の可視化", "3.文脈の可視化"]} 
                image={1}
                onSeeMore={() => { setDetailOpened(true); }}
                {...props}
            />
            <SkillDetailDialog
                title="グラフィックレコーディング"
                open={detailOpened}
                onClose={() => { setDetailOpened(false); }}
                content1={<GraRecoContent1 />}
                content2={<GraRecoContent2 />}
            />
        </>
    );
}