import * as React from "react";
import { Button, Typography } from "@mui/material";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";

/** 右矢印ボタン プロパティ */
export interface IRightArrowButtonProps extends MUIStyledCommonProps<Theme> {
  /** テキスト */
  text: string;
  /** クリックイベント */
  onClick: () => void;
}

const MyRoot = styled(Button)({
  display: "flex",
  alignItems: "end",
  padding: "2.0rem 1.0rem"
})
const MyArrowRight = styled('div')({
  height: "1.8rem",
  borderRight: "3px solid #000000",
  transform: "rotate(-45deg)",
  marginLeft: "-0.8rem",
  marginBottom: "-0.2rem"
})

/** 右矢印ボタン */
export default function RightArrowButton({
  text,
  onClick,
  ...props
}: IRightArrowButtonProps) {
  return (
    <MyRoot onClick={onClick} {...props}>
      <Typography
        fontSize={"2.0rem"}
        fontWeight={700}
        letterSpacing="0.1rem"
        borderBottom="3px solid #000000"
        paddingRight="3.0rem"
      >
        {text}
      </Typography>
      <MyArrowRight />
    </MyRoot>
  );
}
