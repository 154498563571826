import * as React from "react";
import { styled } from "@mui/system";
import SectionTitle from "../atoms/common/sectionTitle";
import SectionDescription from "../atoms/common/sectionDescription";
import PageDownButton from "../atoms/common/pageDownButton";
import NeedCard from "../blocks/needCard";
import ScrollAnchor from "../atoms/common/scrollAnchor";

const MyHowArea = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  height: "calc(100vh - 10.0rem - 2.0rem)",
  paddingBottom: "2.0rem"
})
const MyTitleArea = styled("div")({
  width: "100%"
})
const MyDescptionArea = styled("div")({
  width: "100%"
})
const MySectionDescription = styled(SectionDescription)({
  width: "100.0rem"
})
const MyNeedArea = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  columnGap: "8.0rem"
})
const MyNeed = styled(NeedCard)({
  width: "420px"
})

/** How領域1 */
export default function HowArea1() {
  return (
    <>
      <ScrollAnchor id="how1" />
      <MyHowArea>
        <MyTitleArea>
          <SectionTitle text1="How" text2="できること" />
        </MyTitleArea>
        <MyDescptionArea>
          <MySectionDescription
            id="how-description"
            strings={[
            "多様な可視化アプローチで",
            "お悩みごとに適したビジュアル支援を行います。",
            ]}
          />
        </MyDescptionArea>
        <MyNeedArea>
          <MyNeed
            id="testcard1"
            title={["生の会話を", "わかりやすく記録したい"]}
            subTitle="1.会話の可視化"
            text="そんな方には"
            image={1}
          />
          <MyNeed
            id="testcard1"
            title={["脳内のアイデアや", "イメージを絵にしたい"]}
            subTitle="2.構想の可視化"
            text="そんな方には"
            image={2}
          />
          <MyNeed
            id="testcard1"
            title={["情報をもっと", "わかりやすくまとめしたい"]}
            subTitle="3.文脈の可視化"
            text="そんな方には"
            image={3}
          />
        </MyNeedArea>
        <div><PageDownButton to="how2" fontSize="4.0rem" /></div>
      </MyHowArea>
    </>
  );
}
