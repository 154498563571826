import * as React from "react";
import { styled } from "@mui/system";
import ScrollAnchor from "../atoms/common/scrollAnchor";
import OtherText from "../atoms/contact/otherText";
import GoToPageButton from "../atoms/common/goToPageButton";
import PageUpButton from "../atoms/common/pageUpButton";

const MyContactArea = styled("div")({
    display: "flex",
    flexDirection: "column",
    rowGap: "4.0rem",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 10.0rem - 2.0rem)"
  })

/** Contact領域 */
export default function ContactArea() {
    return (
        <>
            <ScrollAnchor id="contact" />
            <MyContactArea>
                <OtherText text="その他イラストもお受けしています。まずはお気軽にご相談ください。" />
                <GoToPageButton to="/contact" text="相談する・依頼する" />
                <PageUpButton to="what" fontSize="4.0rem" />
            </MyContactArea>
        </>
    );
}