import * as React from "react";
import { MUIStyledCommonProps, Theme } from "@mui/system";
import { IconButton } from "@mui/material";
import { KeyboardDoubleArrowDown } from "@mui/icons-material";
import scrollTo from "gatsby-plugin-smoothscroll";

/** ページダウンボタン プロパティ */
export interface IPageDownButtonProps extends MUIStyledCommonProps<Theme> {
    /** スクロール先要素のid */
    to: string;
    /** フォントサイズ */
    fontSize?: string
}

/** ページダウンボタン */
export default function PageDownButton({ to, fontSize, ...props }: IPageDownButtonProps) {
    return (
        <IconButton onClick={() => { scrollTo(`#${to}`, "start"); }} {...props}>
            <KeyboardDoubleArrowDown style={{ fontSize: fontSize }} />
        </IconButton>
    )
}