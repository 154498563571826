import * as React from "react";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";
import SkillDetailText from "../../../atoms/skill/skillDetailText";
import SkillDetailSection from "../../../atoms/skill/skillDetailSection";
import Skill from "../../../atoms/skill/skill";
import SkillDetailImage1 from "../../../atoms/skill/skillDetailImage1";

/** スキル詳細カード グラフィックレコーディング コンテンツ1 プロパティ */
export interface IContent1Props extends MUIStyledCommonProps<Theme> { }

const MySkillDetailText = styled(SkillDetailText)({
    width: "80%"
})
const MyDescriptionArea = styled("div")({
    marginTop: "3.0rem",
    display: "flex"
})
const MyEffectArea = styled("div")({
    display: "flex",
    columnGap: "1.0rem"
})
const MyTextArea = styled("div")({
    paddingLeft: "2.0rem",
    display: "flex",
    flexDirection: "column",
    rowGap: "3.0rem",
    width: "50%"
})
const MyDetailRow = styled("div")({
    display: "flex",
    columnGap: "3.0rem"
})
const MyEtc = styled(SkillDetailText)({
    display: "flex",
    justifyContent: "end"
})
const MyImageArea = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%"
})

/** スキル詳細カード グラフィックレコーディング コンテンツ1 */
export default function Content1({ ...props }: IContent1Props) {
    return (
        <div {...props}>
            <MySkillDetailText
                id="skillcard1-gra-reco"
                strings={[
                    "様々な議論や対話の内容を文字とイラストを用いてリアルタイムに記録します。",
                    "アナログ・デジタル作画、オンライン・オフライン対応、どちらも可能です。"]}
            />
            <MyDescriptionArea>
                <MyTextArea>
                    <SkillDetailSection title="効果">
                        <MyEffectArea>
                            <Skill text="1.会話の可視化" />
                            <Skill text="3.文脈の可視化" />
                        </MyEffectArea>
                        <SkillDetailText id="skillcard1-effect" strings={[
                            "議論や対話の内容をわかりやすく振り返ることができる",
                            "記録がより視認性の高い形で第三者へ共有することができる"
                        ]} />
                    </SkillDetailSection>
                    <MyDetailRow>
                        <SkillDetailSection title="おおまかな制作の流れ">
                            <SkillDetailText id="skillcard1-flow" strings={[
                                "打ち合わせ",
                                "↓",
                                "当日：グラフィックレコーディング",
                                "↓",
                                "修正確認・修正箇所反映",
                                "↓",
                                "当日〜近日：納品"
                            ]} />
                        </SkillDetailSection>
                        <SkillDetailSection title="制作実績">
                            <SkillDetailText
                                id="skillcard1-achievement"
                                strings={["・株式会社XXXXX", "・株式会社XXXXX", "・株式会社XXXXX", "・株式会社XXXXX"]}
                                end={<MyEtc id="skillcard1-achievement-etc" strings={["etc"]} />}
                            />
                        </SkillDetailSection>
                    </MyDetailRow>
                </MyTextArea>
                <MyImageArea>
                    <SkillDetailImage1 />
                </MyImageArea>
            </MyDescriptionArea>
        </div>
    );
}