import * as React from "react";
import { styled } from "@mui/system";
import SectionTitle from "../atoms/common/sectionTitle";
import SkillCard from "../blocks/skillCard";
import ScrollAnchor from "../atoms/common/scrollAnchor";
import GraRecoCard from "./skillCard/graReco/card";
import SceenCard from "./skillCard/sceen/card";
import VisionCard from "./skillCard/vision/card";
import WorkshopCard from "./skillCard/workshop/card";

const MyHowArea = styled("div")({
  paddingBottom: "3.0rem"
})
const MyTitleArea = styled("div")({
  marginBottom: "4.0rem"
})
const MySkillCardArea = styled("div")({
  paddingLeft: "2.0rem",
  display: "flex",
  flexDirection: "column",
  rowGap: "3.0rem"
})
const MySkillCardLeft = styled(SkillCard)({
  display: "flex",
  justifyContent: "start"
})
const MySkillCardRight = styled(SkillCard)({
  display: "flex",
  justifyContent: "end"
})
const MyGraRecoCard = styled(GraRecoCard)({
  display: "flex",
  justifyContent: "start"
})
const MySceenCard = styled(SceenCard)({
  display: "flex",
  justifyContent: "end"
})
const MyVisionCard = styled(VisionCard)({
  display: "flex",
  justifyContent: "start"
})
const MyWorkshopCard = styled(WorkshopCard)({
  display: "flex",
  justifyContent: "end"
})

/** How領域2 */
export default function HowArea2() {
  return (
    <>
      <ScrollAnchor id="how2" />
      <MyHowArea>
          <MyTitleArea>
            <SectionTitle text1="How" text2="できること" />
          </MyTitleArea>
          <MySkillCardArea>
            <MyGraRecoCard />
            <MySceenCard />
            <MyVisionCard />
            <MyWorkshopCard />
          </MySkillCardArea>
      </MyHowArea>
    </>
  );
}
