import * as React from "react";
import { styled } from "@mui/system";
import { Modal } from "@mui/material"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SkillDetailTitle from "../atoms/skill/skillDetailTitle";
import SkillDetailCloseButton from "../atoms/skill/skillDetailCloseButton";
import RightArrowButton from "../atoms/common/rightArrowButton";
import LeftArrowButton from "../atoms/common/leftArrowButton";

/** Skill詳細ダイアログ プロパティ */
export interface ISkillDetailDialogProps {
    /** タイトル */
    title: string;
    /** 開閉状態 */
    open: boolean;
    /** クローズイベント */
    onClose: () => void;
    /** 子要素1 */
    content1: React.ReactNode;
    /** 子要素2 */
    content2: React.ReactNode;
}

const MyModalContents = styled("div")({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    height: "90vh",
    backgroundColor: "white",
    padding: "2.0rem 3.0rem"
})
const MySkillDetailCloseButton = styled(SkillDetailCloseButton)({
    position: "absolute",
    top: "1.5rem",
    right: "1.5rem"
})
const MySkillDetailTitle = styled(SkillDetailTitle)({
    height: "4.2rem"
})
const MySkillDetailContents = styled("div")({
    position:"relative",
    height: "calc(90vh - 4.2rem)",
    overflow: "hidden"
})
const MySwiper = styled(Swiper)({
    height: "100%"
})
const MyRightArrowButton = styled(RightArrowButton)({
    position: "absolute",
    right: "0rem",
    bottom: "0rem"
})
const MyLeftArrowButton = styled(LeftArrowButton)({
    position: "absolute",
    left: "0rem",
    bottom: "0rem"
})

/** Skill詳細ダイアログ */
export default function SkillDetailDialog({ title, open, onClose, content1, content2 }: ISkillDetailDialogProps) {

    const [ swiperInstance, setSwiperInstance ] = React.useState<any>()

    return (
        <Modal 
            open={open} 
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <MyModalContents>
                <MySkillDetailCloseButton onClick={onClose} fontSize="4.0rem" />
                <MySkillDetailTitle text={title} />
                <MySkillDetailContents>
                    <MySwiper onInit={(s) => { setSwiperInstance(s); }}>
                        <SwiperSlide>
                            {content1}
                            <MyRightArrowButton text="事例を見る" onClick={() => { if(swiperInstance) swiperInstance.slideNext(); }} />
                        </SwiperSlide>
                        <SwiperSlide>
                            {content2}
                            <MyLeftArrowButton text="説明に戻る" onClick={() => { if(swiperInstance) swiperInstance.slidePrev(); }} />
                        </SwiperSlide>
                    </MySwiper>
                </MySkillDetailContents>
            </MyModalContents>
        </Modal>
    );
}