import * as React from "react";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";
import NeedTitle from "../atoms/need/needTitle";
import NeedBackground from "../atoms/need/needBackground";
import NeedImage1 from "../atoms/need/needImage1";
import NeedImage2 from "../atoms/need/needImage2";
import NeedImage3 from "../atoms/need/needImage3";
import NeedImage1_2 from "../atoms/need/needImage1_2";
import NeedImage2_2 from "../atoms/need/needImage2_2";
import NeedImage3_2 from "../atoms/need/needImage3_2";
import NeedSubTitle from "../atoms/need/needSubTitle";
import NeedText from "../atoms/need/needText";
import NeedImageSlider from "../atoms/need/needImageSlider";

/** ニーズカード プロパティ */
export interface INeedCardProps extends MUIStyledCommonProps<Theme> {
    /** ID */
    id: string;
    /** タイトルテキスト */
    title: string[];
    /** サブタイトル */
    subTitle: string
    /** テキスト */
    text: string
    /** 画像 */
    image: 1 | 2 | 3
}

const MyRoot = styled("div")({
})
const MyNeedTitle = styled(NeedTitle)({
    position: "relative",
    zIndex: 2
})
const MyNeedBackground = styled(NeedBackground)({
    position: "relative",
    zIndex: 1,
    marginTop: "-4.0rem",
    paddingTop: "6.0rem",
    paddingBottom: "2.0rem",
    marginLeft: "1.0rem",
    marginRight: "1.0rem"
})

const MySlider = styled(NeedImageSlider)({
    width: "100%"
})

/** ニーズカード */
export default function NeedCard({ id, title, subTitle, text, image, ...props }: INeedCardProps) {

    const image1 = image === 1 ? <NeedImage1 /> : image === 2 ? <NeedImage2 /> : <NeedImage3 />;
    const image2 = image === 1 ? <NeedImage1_2 /> : image === 2 ? <NeedImage2_2 /> : <NeedImage3_2 />;

    return (
        <MyRoot { ...props }>
            <MyNeedTitle id={id} strings={title} />
            <MyNeedBackground>
                <MySlider child1={image1} child2={image2} />
                <NeedText text={text} />
                <NeedSubTitle text={subTitle} />
            </MyNeedBackground>
        </MyRoot>
    )
}