import * as React from "react";
import { Typography } from "@mui/material";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";

/** スキル詳細 セクション プロパティ */
export interface ISkillDetailSectionProps extends MUIStyledCommonProps<Theme> {
    /** セクションタイトル */
    title: string;
    /** 子要素 */
    children: React.ReactNode;
}

const MyRoot = styled("div")({
    borderLeft: "1.0rem solid #B3F3E0",
    paddingLeft: "2.0rem"
})
const MyContents = styled("div")({
    marginTop: "1.5rem",
    display: "flex",
    flexDirection: "column",
    rowGap: "1.5rem"
})

/** スキル詳細 セクション */
export default function SkillDetailSection({ title, children, ...props }: ISkillDetailSectionProps) {
    return (
        <MyRoot { ...props }>
            <Typography fontWeight={700} fontSize="1.8rem">{title}</Typography>
            <MyContents>{children}</MyContents>
        </MyRoot>
    );
}