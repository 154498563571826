import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { MUIStyledCommonProps, Theme } from "@mui/system";

/** 実績 ビジョンイラスト 画像１ プロパティ */
export interface IImage1Props extends MUIStyledCommonProps<Theme> {}

/** 実績 ビジョンイラスト 画像１ */
export default function Image1({ ...props }: IImage1Props) {
    return (
        <div { ...props }>
            <StaticImage
                src="../../../../images/AchievementVision1.png"
                alt="AchievementVision1"
                placeholder="blurred"
                layout="fixed"
                width={800}
                height={500}
            />
        </div>
    )
}