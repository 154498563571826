import * as React from "react";
import { MUIStyledCommonProps, Theme } from "@mui/system";
import SkillCard from "../../../blocks/skillCard";
import SkillDetailDialog from "../../skillDetailDialog";
import Content1 from "./content1";
import Content2 from "./content2";

/** スキル詳細カード シーンスケッチ プロパティ */
export interface ICardProps extends MUIStyledCommonProps<Theme> { }

/** スキル詳細カード シーンスケッチ */
export default function Card({ ...props }: ICardProps) {
    const [detailOpened, setDetailOpened] = React.useState<boolean>(false);

    return (
        <>
            <SkillCard 
                id="skillcard1-skill-sceen" 
                title="シーンスケッチ" 
                textList={["アイデアをリアルタイムに描き起こしたり、","ユーザーシーン等の構想をビジュアライズします。"]}
                skills={["2.構想の可視化"]}
                image={2}
                onSeeMore={() => { setDetailOpened(true); }}
                {...props}
            />
            <SkillDetailDialog
                title="シーンスケッチ"
                open={detailOpened}
                onClose={() => { setDetailOpened(false); }}
                content1={<Content1 />}
                content2={<Content2 />}
            />
        </>
    );
}