import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { MUIStyledCommonProps, Theme } from "@mui/system";

/** 実績 グラフィックレコーディング 画像３ プロパティ */
export interface IImage3Props extends MUIStyledCommonProps<Theme> {}

/** 実績 グラフィックレコーディング 画像３
 */
export default function Image3({ ...props }: IImage3Props) {
    return (
        <div { ...props }>
            <StaticImage
                src="../../../../images/AchievementGraReco3.png"
                alt="AchievementGraReco3"
                placeholder="blurred"
                layout="fixed"
                width={800}
                height={500}
            />
        </div>
    )
}