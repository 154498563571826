import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { MUIStyledCommonProps, Theme } from "@mui/system";

/** スキル画像３ プロパティ */
export interface ISkillImage3Props extends MUIStyledCommonProps<Theme> {}

/** スキル画像３ */
export default function SkillImage3({ ...props }: ISkillImage3Props) {
    return (
        <div { ...props }>
            <StaticImage
                src="../../../images/Skill3.png"
                alt="Skill3"
                placeholder="blurred"
                layout="fixed"
                width={350}
                height={250}
            />
        </div>
    )
}