import * as React from "react";
import { styled } from "@mui/system";
import SectionTitle from "../atoms/common/sectionTitle";
import SectionDescription from "../atoms/common/sectionDescription";
import GoToSectionButton from "../blocks/goToSectionButton";
import Circle1 from "../atoms/what/circle1";
import Circle2 from "../atoms/what/circle2";
import Circle3 from "../atoms/what/circle3";
import ScrollAnchor from "../atoms/common/scrollAnchor";

const MyWhatArea = styled("div")({
  display: "flex",
  height: "calc(100vh - 10.0rem - 2.0rem)",
  paddingBottom: "2.0rem"
})
const MyWhatLeft = styled("div")({
  paddingTop: "3.0rem",
  width: "40%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
})
const MyWhatRight = styled("div")({
  width: "60%",
  position: "relative"
})
const MyGoToSectionButtonArea = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "2.0rem"
})
const MyCircle1 = styled(Circle1)({
  position: "absolute"
})
const MyCircle2 = styled(Circle2)({
  position: "absolute",
  right: "0rem"
})
const MyCircle3 = styled(Circle3)({
  position: "absolute",
  left: "calc(50% - (30.0rem / 2))",
  bottom: "0rem"
})

/** What領域 */
export default function WhatArea() {
  return (
    <>
      <ScrollAnchor id="what" />
      <MyWhatArea>
        <MyWhatLeft>
          <SectionTitle text1="What" text2="なにをしているか" />
          <SectionDescription
              id="what-description"
              strings={[
              "コミュニケーションにおける",
              "対話や思考の",
              "ビジュアル支援を行います。",
              ]}
          />
          <MyGoToSectionButtonArea>
              <div><GoToSectionButton to="how1" text1="How" text2="できること" /></div>
              <div><GoToSectionButton page="who" text1="Who" text2="どんな人か" /></div>
          </MyGoToSectionButtonArea>
        </MyWhatLeft>
        <MyWhatRight>
          <MyCircle1 />
          <MyCircle2 />
          <MyCircle3 />
        </MyWhatRight>
      </MyWhatArea>
    </>
  );
}
