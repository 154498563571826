import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { MUIStyledCommonProps, Theme } from "@mui/system";

/** 実績 グラフィックレコーディング 画像２ プロパティ */
export interface IImage2Props extends MUIStyledCommonProps<Theme> {}

/** 実績 グラフィックレコーディング 画像２ */
export default function Image2({ ...props }: IImage2Props) {
    return (
        <div { ...props }>
            <StaticImage
                src="../../../../images/AchievementGraReco2.png"
                alt="AchievementGraReco2"
                placeholder="blurred"
                layout="fixed"
                width={800}
                height={500}
            />
        </div>
    )
}