import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";

/** スクロール先 プロパティ */
export interface IScrollAnchorProps extends MUIStyledCommonProps<Theme> {
    /** ID */
    id: string;
}

const MyRoot = styled("div")({
    position: "relative",
})
const MyAnchor = styled("div")({
    position: "absolute",
    marginTop: "-10.0rem"
})

/** スクロール先 */
export default function ScrollAnchor({ id }: IScrollAnchorProps) {
    return (
        <MyRoot>
            <MyAnchor id={id} />
        </MyRoot>
    )
}