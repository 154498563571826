import * as React from "react";
import { Button } from "@mui/material";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";
import scrollTo from "gatsby-plugin-smoothscroll";
import SectionTitle from "../atoms/common/sectionTitle";
import Arrow from "../atoms/common/arrow";
import { navigate } from "gatsby";

/** セクション移動ボタン プロパティ */
export interface IGoToSectionButtonProps extends MUIStyledCommonProps<Theme> {
  /** 遷移先ページ */
  page?: string;
  /** スクロール先要素のid */
  to?: string;
  /** テキスト1 */
  text1: string;
  /** テキスト2 */
  text2: string;
}

const MyButton = styled(Button)({
  textTransform: "none",
  padding: "2.5rem",
  borderRadius: "20px",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "whitesmoke"
  }
});

const MyArrow = styled(Arrow)({
  marginLeft: "1.8rem"
});

/** セクション移動ボタン */
export default function GoToSectionButton({
  page,
  to,
  text1,
  text2,
  ...props
}: IGoToSectionButtonProps) {
  return (
    <MyButton
      variant="outlined"
      endIcon={<div></div>}
      onClick={() => {
        if(page) navigate(page);
        if(to) scrollTo(`#${to}`, "start");
      }}
      {...props}
    >
      <SectionTitle text1={text1} text2={text2} />
      <MyArrow />
    </MyButton>
  );
}
