import * as React from "react";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Typography } from "@mui/material";
import LeftButton from "../atoms/common/leftButton";
import RightButton from "../atoms/common/rightButton";

/** 画像スライダー プロパティ */
export interface IIMageSliderProps extends MUIStyledCommonProps<Theme> {
    /** ID */
    id: string;
    /** 画像 */
    images: React.ReactNode[];
    /** キャプション */
    caption: string;
    /** 幅 */
    width?: string;
}

const MyImageArea = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})
const MySwiper = styled(Swiper)({
    marginLeft: "0rem !Important",
    marginRight: "0rem !Important",
    width: "auto !Important"
})
const MyCaption = styled(Typography)({
    marginTop: "1.0rem",
    display: "flex",
    justifyContent: "center"
})

/** 画像スライダー */
export default function IMageSlider({ id, images, caption, width, ...props }: IIMageSliderProps) {

    const [ swiperInstance, setSwiperInstance ] = React.useState<any>()

    return (
        <div {...props}>
            <MyImageArea>
                <LeftButton onClick={() => { if(swiperInstance) swiperInstance.slidePrev(); }} fontSize="4.0rem" />
                <div style={{ width: width }}>
                    <MySwiper loop onInit={(s) => { setSwiperInstance(s); }}>
                        {images.map((image, i) => {
                            return (<SwiperSlide key={`image-slider-${id}-${i}`}>{image}</SwiperSlide>)
                        })}
                    </MySwiper>
                </div>
                <RightButton onClick={() => { if(swiperInstance) swiperInstance.slideNext(); }} fontSize="4.0rem" />
            </MyImageArea>
            <MyCaption
                fontSize="1.6rem"
                fontWeight={500}
            >{caption}</MyCaption>
        </div>
    )
}