import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { MUIStyledCommonProps, Theme } from "@mui/system";

/** 円形画像２ プロパティ */
export interface ICircle2Props extends MUIStyledCommonProps<Theme> {}

/** 円形画像２ */
export default function Circle2({ ...props }: ICircle2Props) {
    return (
        <div { ...props }>
            <StaticImage
                src="../../../images/circle2.png"
                alt="Circle2"
                placeholder="blurred"
                layout="fixed"
                width={300}
            />
        </div>
    )
}