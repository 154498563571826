import * as React from "react";
import { Typography } from "@mui/material";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";

/** スキル プロパティ */
export interface ISkillProps extends MUIStyledCommonProps<Theme> {
  /** テキスト */
  text: string;
}

const MyRoot = styled('div')({
  border: "1px solid #000000",
  borderRadius: "5.0rem",
  padding: "1.0rem 3.0rem"
})

/** スキル */
export default function Skill({
  text,
  ...props
}: ISkillProps) {
  return (
    <MyRoot {...props}>
      <Typography
        fontSize={"1.5rem"}
        fontWeight={600}
        letterSpacing={"0.1rem"}
      >
        {text}
      </Typography>
    </MyRoot>
  );
}
