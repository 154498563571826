import * as React from "react";
import PageHeader from "../components/blocks/pageHeader";
import PageBody from "../components/blocks/pageBody";
import WhatArea from "../components/molecules/whatArea";
import HowArea1 from "../components/molecules/howArea1";
import HowArea2 from "../components/molecules/howArea2";
import ContactArea from "../components/molecules/contactArea";

/** 初期ページ */
export default function Index() {
  return (
    <>
      <PageHeader />
      <PageBody>
        <WhatArea />
        <HowArea1 />
        <HowArea2 />
        <ContactArea />
      </PageBody>
    </>
  );
}