import * as React from "react";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";

/** スキル背景 プロパティ */
export interface ISkillBackgroundProps extends MUIStyledCommonProps<Theme> {
    /** 子ノード */
    children: React.ReactNode;
}

const MyRoot = styled('div')({
    backgroundColor: "RGB(179,243,224)",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    rowGap: "1.0rem",
    justifyContent: "center",
    alignItems: "center",
    padding: "1.0rem 4.0rem"
})
const MyBox = styled('div')({
    backgroundColor: "white",
    padding: "3.0rem 3.0rem 1.0rem 3.0rem",
    display: "flex",
    width:  "70vw"
})

/** スキル背景 */
export default function SkillBackground({ children, ...props }: ISkillBackgroundProps) {
    return (
        <MyRoot>
            <MyBox {...props}>{children}</MyBox>
        </MyRoot>
    )
}