import * as React from "react";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";
import ImageSlider from "../../../blocks/imageSlider";
import Image1 from "../../../atoms/achievement/vision/image1";
import Image2 from "../../../atoms/achievement/vision/image2";
import Image3 from "../../../atoms/achievement/vision/image3";

/** スキル詳細カード ビジョンイラスト コンテンツ2 プロパティ */
export interface IContent2Props extends MUIStyledCommonProps<Theme> { }

const MyRoot = styled("div")({
    height: "100%"
})
const MyImage = styled(ImageSlider)({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
})

/** スキル詳細カード ビジョンイラスト コンテンツ2 */
export default function Content2({ ...props }: IContent2Props) {
    return (
        <MyRoot {...props}>
            <MyImage
                id="vision-image"
                images={[
                    <Image1 />,
                    <Image2 />,
                    <Image3 />
                ]}
                caption="例"
                width="800px"
            />
        </MyRoot>
    )
}