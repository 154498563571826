import * as React from "react";
import { Typography } from "@mui/material";
import { MUIStyledCommonProps, Theme } from "@mui/system";

/** その他テキスト プロパティ */
export interface IOtherTextProps extends MUIStyledCommonProps<Theme> {
  /** テキスト */
  text: string;
}

/** その他テキスト */
export default function OtherText({
  text,
  ...props
}: IOtherTextProps) {
  return (
    <div {...props}>
      <Typography
        fontSize={"2.4rem"}
        fontWeight={600}
        letterSpacing={"0.1rem"}
      >
        {text}
      </Typography>
    </div>
  );
}
