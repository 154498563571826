import * as React from "react";
import { MUIStyledCommonProps, Theme } from "@mui/system";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

/** スキル詳細ダイアログ閉じるボタン プロパティ */
export interface ISkillDetailCloseButtonProps extends MUIStyledCommonProps<Theme> {
    /** クリックイベント */
    onClick: () => void;
    /** フォントサイズ */
    fontSize?: string;
}

/** スキル詳細ダイアログ閉じるボタン */
export default function SkillDetailCloseButton({ onClick, fontSize, ...props }: ISkillDetailCloseButtonProps) {
    return (
        <IconButton onClick={onClick} {...props}>
            <Close style={{ fontSize: fontSize }} />
        </IconButton>
    )
}