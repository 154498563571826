import * as React from "react";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";
import { Typography } from "@mui/material";

/** ニーズタイトル プロパティ */
export interface INeedTitleProps extends MUIStyledCommonProps<Theme> {
    /** ID */
    id: string;
    /** テキスト */
    strings: string[]
}

const MyRoot = styled('div')({
    backgroundColor: "white",
    border: "2px solid black",
    borderRadius: "70px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5rem"
})

/** ニーズタイトル */
export default function NeedTitle({ id, strings, ...props }: INeedTitleProps) {
    return (
        <MyRoot { ...props }>
            {strings.map((text, i) => {
                return (
                    <Typography
                        lineHeight={"4rem"}
                        fontWeight={600}
                        key={`need-title-${id}-${i}`}
                    >
                        {text}
                    </Typography>
                )
            })}
        </MyRoot>
    )
}