import * as React from "react";
import { Typography } from "@mui/material";
import { MUIStyledCommonProps, Theme } from "@mui/system";

/** ニーズテキスト プロパティ */
export interface INeedTextProps extends MUIStyledCommonProps<Theme> {
  /** テキスト */
  text: string;
}

/** ニーズテキスト */
export default function NeedText({
  text,
  ...props
}: INeedTextProps) {
  return (
    <div {...props}>
      <Typography
        fontSize={"2.2rem"}
        fontWeight={600}
      >
        {text}
      </Typography>
    </div>
  );
}
