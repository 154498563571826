import * as React from "react";
import { MUIStyledCommonProps, Theme } from "@mui/system";
import { IconButton } from "@mui/material";
import { KeyboardDoubleArrowUp } from "@mui/icons-material";
import scrollTo from "gatsby-plugin-smoothscroll";

/** ページアップボタン プロパティ */
export interface IPageUpButtonProps extends MUIStyledCommonProps<Theme> {
    /** スクロール先要素のid */
    to: string;
    /** フォントサイズ */
    fontSize?: string
}

/** ページアップボタン */
export default function PageUpButton({ to, fontSize, ...props }: IPageUpButtonProps) {
    return (
        <IconButton onClick={() => { scrollTo(`#${to}`, "start"); }} {...props}>
            <KeyboardDoubleArrowUp style={{ fontSize: fontSize }} />
        </IconButton>
    )
}