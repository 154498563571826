import * as React from "react";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";

/** ニーズ背景 プロパティ */
export interface INeedBackgroundProps extends MUIStyledCommonProps<Theme> {
    /** 子ノード */
    children: React.ReactNode
}

const MyRoot = styled('div')({
    backgroundColor: "RGB(179,243,224)",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    rowGap: "1.0rem",
    justifyContent: "center",
    alignItems: "center"
})

/** ニーズ背景 */
export default function NeedBackground({ children, ...props }: INeedBackgroundProps) {
    return (
        <MyRoot { ...props }>{children}</MyRoot>
    )
}