import * as React from "react";
import { Typography } from "@mui/material";
import { MUIStyledCommonProps, Theme } from "@mui/system";

/** スキルタイトル プロパティ */
export interface ISkillTitleProps extends MUIStyledCommonProps<Theme> {
  /** テキスト */
  text: string;
}

/** スキルタイトル */
export default function SkillTitle({
  text,
  ...props
}: ISkillTitleProps) {
  return (
    <div {...props}>
      <Typography
        fontSize={"3.2rem"}
        fontWeight={700}
      >
        {text}
      </Typography>
    </div>
  );
}
