import * as React from "react";
import { MUIStyledCommonProps, Theme } from "@mui/system";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

/** ニーズ画像スライダー プロパティ */
export interface INeedImageSliderProps extends MUIStyledCommonProps<Theme> {
    /** スライド要素1 */
    child1: React.ReactNode
    /** スライド要素2 */
    child2: React.ReactNode
}

/** ニーズ画像スライダー */
export default function NeedImageSlider({ child1, child2, ...props }: INeedImageSliderProps) {

    const [ swiperInstance, setSwiperInstance ] = React.useState<any>()

    return (
        <div 
            { ...props }
            onMouseOver={() => { if(swiperInstance) swiperInstance.slideNext(); }}
            onMouseLeave={() => { if(swiperInstance) swiperInstance.slidePrev(); }}
        >
            <Swiper onInit={(s) => { setSwiperInstance(s); s.slidePrev() }}>
                <SwiperSlide>{child1}</SwiperSlide>
                <SwiperSlide>{child2}</SwiperSlide>
            </Swiper>
        </div>
    )
}